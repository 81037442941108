import React from "react";
import ThemeConfig from "Theme/ThemeConfig";
import { ThemeProvider } from "@material-ui/core/styles";
import  UnsubscribeForm  from "./UnsubscribeForm";
import backgroundPattern from "assets/misc/pattern.png";

 const Unsubscribe = props => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const uuid = urlParams.get("uuid");
    return (
        <ThemeProvider theme={ThemeConfig}>
            <div style={{backgroundImage:`url(${backgroundPattern})`,backgroundSize:"auto, cover", minHeight:"500px" , height:"auto"}}>
            <UnsubscribeForm uuid={uuid}/>
            </div>
        </ThemeProvider>
    );
};
export default Unsubscribe;