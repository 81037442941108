import React from "react";

// Banner Section *
import BannerImage from "components/VisualComponents/BannerImage";

import Illustrations from "assets/IllustrationsList";
import Backgrounds from "assets/BackgroundList";

const CouncelorBanner = props => {
  return <BannerImage sectionImage={Illustrations.hi} showForm="userSignup" />;
};

export default CouncelorBanner;
