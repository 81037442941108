import React from "react";
import Grid from "@material-ui/core/Grid";
import Carousel from "react-material-ui-carousel";
import QuoteUp from "assets/misc/quote_up.svg";
import QuoteDown from "assets/misc/quote_down.svg";

import { Typography, Box, Container } from "@material-ui/core";

const Testimonials = props => {
  const {
    sectionTitle,
    testimonialsContent,
    backgroundImage,
    backgroundColor
  } = props;
  return (
    <>
      <Box
        style={{
          background: backgroundColor
            ? backgroundColor
            : `url(${backgroundImage}) no-repeat center center fixed`,
          backgroundSize: "cover"
        }}
        pb={10}
        pt={10}
        minHeight={440}
      >
        <Container fixed>
          <Grid container alignItems="flex-start" display="row">
            <Grid
              item
              cs={4}
              container
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Typography
                color="secondary"
                align="center"
                style={{
                  fontWeight: "700",
                  fontSize: "24px",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: 1.25,
                  letterSpacing: "normal"
                }}
              >
                {sectionTitle}
              </Typography>
            </Grid>
          </Grid>

          <Box mt={3}>
            <Grid
              cs={4}
              container
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Grid item lg={5} md={8}>
                <Box>
                  <img src={QuoteUp}
                    className={"quoteUp"}
                       />
                  <Carousel animation="fade" interval={5000}>
                    {testimonialsContent.map((testimonial, index) => {
                      return (
                        <Box key={index}>
                          <Box>
                            <Typography
                              align="center"
                              style={{
                                minHeight: "380px",
                                height: "auto",
                                fontSize: "20px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: 1.89,
                                letterSpacing: "normal"
                              }}
                            >
                              {testimonial.content}
                            </Typography>
                            <img src={QuoteDown}
                                className={"quoteDown"}
                            />
                          </Box>
                          <Box mt={2}>
                            <Typography
                              align="center"
                              style={{ fontWeight: "bolder" }}
                            >
                              {testimonial.user}, {testimonial.age}
                            </Typography>
                            <Typography
                              align="center"
                              style={{
                                fontWeight: "lighter",
                                color: "#4a4a4a",
                                fontSize: "14px"
                              }}
                            >
                              {testimonial.category}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Carousel>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Testimonials;
