import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import Divider from "@material-ui/core/Divider";
import { Typography, Box, Collapse } from "@material-ui/core";
const useStyles = makeStyles(theme => ({
  activeTitle: {
    color: "#df8166",
    fontWeight: "bold",
    fontSize: "22px",
    cursor: "pointer"
  },
  inactiveTitle: {
    color: "#9b9b9b",
    fontWeight: "bold",
    fontSize: "22px",
    cursor: "pointer"
  },
  activeDivider: {
    backgroundColor: "#df8166",
    height: "3px"
  },
  inactiveDivider: {
    backgroundColor: "#2b292d",
    height: "1px",
    opacity: 0.15
  }
}));
const ToggleList = props => {
  const { toggleListItems } = props;
  const classes = useStyles();
  const [activeTitle, setActiveTitle] = useState([]);
  const [activeDivider, setActiveDivider] = useState([]);
  const [show, setShow] = useState([]);

  useEffect(() => {
    const showTemp = { ...show };
    const activeTitleTemp = { ...activeTitle };
    const activeDividerTemp = { ...activeDivider };
    toggleListItems.forEach((item, index) => {
      showTemp[index] = false;
      activeTitleTemp[index] = classes.inactiveTitle;
      activeDividerTemp[index] = classes.inactiveDivider;
    });

    showTemp[0] = true;
    activeTitleTemp[0] = classes.activeTitle;
    activeDividerTemp[0] = classes.activeDivider;

    setShow(showTemp);
    setActiveDivider(activeDividerTemp);
    setActiveTitle(activeTitleTemp);
  }, [toggleListItems]);

  const handleChange = index => {
    const showTemp = { ...show };
    const activeTitleTemp = { ...activeTitle };
    const activeDividerTemp = { ...activeDivider };
    // showTemp[index] ? (showTemp[index] = false) : (showTemp[index] = true);

    Object.keys(show).forEach(item =>
      parseInt(item) !== index
        ? ((showTemp[parseInt(item)] = false),
          ((activeDividerTemp[parseInt(item)] = classes.inactiveDivider),
          (activeTitleTemp[parseInt(item)] = classes.inactiveTitle)))
        : ((showTemp[index] = !showTemp[index]),
          showTemp[index] === false
            ? ((activeDividerTemp[parseInt(item)] = classes.inactiveDivider),
              (activeTitleTemp[index] = classes.inactiveTitle))
            : ((activeDividerTemp[parseInt(item)] = classes.activeDivider),
              (activeTitleTemp[parseInt(item)] = classes.activeTitle)))
    );
    setShow(showTemp);
    setActiveDivider(activeDividerTemp);
    setActiveTitle(activeTitleTemp);
  };
  return (
    <>
      {toggleListItems.map((listItem, index) => {
        return (
          <Box key={index}>
            <Box mt={2}>
              <Typography
                className={activeTitle[index]}
                variant="h6"
                onClick={() => handleChange(index)}
              >
                {listItem.title}
              </Typography>
            </Box>

            <Grid item lg={12} md={8} xs={12}>
              <Collapse in={show[index]}>
                <Box mt={4} mb={1}>
                  <Typography variant="body2">{listItem.content}</Typography>
                </Box>
              </Collapse>
              <Box mt={2}>
                <Divider variant="fullWidth" className={activeDivider[index]} />
              </Box>
            </Grid>
          </Box>
        );
      })}
    </>
  );
};

export default ToggleList;
