// Mobile App Mockups *
import mockup1 from "assets/mockups/mockup_1.png";
import mockup2 from "assets/mockups/mockup_2.png";
import mockup3 from "assets/mockups/mockup_3.png";

const MockupsList = {
  mockup1,
  mockup2,
  mockup3
};

export default MockupsList;
