import React from "react";
import CookieConsent from "react-cookie-consent";
import { Typography, Box, Grid } from "@material-ui/core";
import Illustrations from "assets/IllustrationsList";
import Hidden from "@material-ui/core/Hidden";

const CookieNotice = props => {

  const disableGoogleAnalytics = () => {
    window["ga-disable-UA-160319263-1"] = true;
    window["mhCare_cookie"] = -1;
  };

  const enableGoogleAnalytics = () => {
    window["ga-disable-UA-160319263-1"] = true;
    window["mhCare_cookie"] = 1;
  };

  return (
    <Grid container>
      <Grid item lg={5}>
    <CookieConsent
      location="bottom"
      buttonText="Allow Cookies"
      declineButtonText="Decline"
      cookieName="mhCare_cookie"
      cookieValue={"1"}
      declineCookieValue={"-1"}
      style={{
        background: "#f5f4f0",
        justifyContent: "center",
        color:"#2b292d",
      }}
      buttonStyle={{
        background: "#DF8166",
        color: "#ffffff",
        borderRadius: "20px",
        fontSize: "15px",
        padding: "10px",
        boxShadow: "0 2px 4px 0 #4a4a4a66",
        width: "200px"
      }}
      declineButtonStyle={{
        background: "#97a49a",
        color: "#ffffff",
        borderRadius: "20px",
        boxShadow: "0 2px 4px 0 #4a4a4a66",
        fontSize: "15px",
        padding: "10px",
        width: "200px"
      }}
      expires={150}
      enableDeclineButton
      flipButtons
      contentStyle={{ flex: "0 0 100%", textAlign: "center" }}
      onAccept={enableGoogleAnalytics}
      onDecline={disableGoogleAnalytics}
    >

      <Grid container direction="row" justify="center" alignItems="center" alignContent={"center"}>
      <Hidden smDown={true} xsDown={true}>
        <Grid item lg={5}>
          <Grid container direction="row" justify="center" alignItems="center" alignContent={"center"}>
          <Grid item>
            <img src={Illustrations.plantCare} alt={"Cookie Art"} width="210px"/>
          </Grid>
          </Grid>
        </Grid>
      </Hidden>
        <Grid item lg={10}>
          <Box pl={5} pr={5}>
          <Typography>
            We use cookies to make mhCare website a better place.
            <br/>
            <br/>
            Cookies help to provide a more personalized and relevant experience
            for you, and web analytics for us. No personal details are stored.
          </Typography>
          </Box>
        </Grid>
      </Grid>
    </CookieConsent>
      </Grid>
    </Grid>

  );
};

export default CookieNotice;
