import React from 'react';
import IllustrationsList from 'assets/IllustrationsList';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import {Group, SupervisorAccount, PlaylistAddCheck, PermContactCalendar} from '@material-ui/icons'
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';

import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Grid, Box} from '@material-ui/core'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    }
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

function AdminSidebar(props) {
  const {window, page} = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar}/>
      <Divider/>
      <List>
        <ListItem button key={'patients'}>
          <ListItemIcon><Group/></ListItemIcon>
          <ListItemText primary={'Patients List'}/>
        </ListItem>
        <ListItem button key={'therapists'}>
          <ListItemIcon><SupervisorAccount/></ListItemIcon>
          <ListItemText primary={'Therapists List'}/>
        </ListItem>

      </List>
      <Divider/>
      <List>
        <ListItem button key={'matched'}>
          <ListItemIcon><PlaylistAddCheck/></ListItemIcon>
          <ListItemText primary={'Matched Requests'}/>
        </ListItem>
        <ListItem button key={'completed'}>
          <ListItemIcon><PermContactCalendar/></ListItemIcon>
          <ListItemText primary={'Completed Appointments'}/>
        </ListItem>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <div className={classes.root}>
        <CssBaseline/>

        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>

      </div>
      {page === '' &&
      <>
        <Box mt={8} mb={8}>
          <Grid container direction={'column'} alignItems={'center'} alignContent={'center'} justify={'center'}>
            <Grid item lg={12}>
              <Typography variant={'h2'} color={'secondary'}>
                Welcome Admin
              </Typography>
            </Grid>
            <Box>
              <Grid item lg={12}>
                <img src={IllustrationsList.chilling} alt={'Welcome Clipart'}/>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </>
      }
    </>
  );
}

export default AdminSidebar;
