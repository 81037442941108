import React, {useEffect, useState, useMemo, useCallback} from 'react'
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import {API_URL} from 'config'
import {Box, Button} from '@material-ui/core'
import AppointmentsModal from 'pages/Admin/Appointments/AppointmentsModal'
import moment from 'moment-timezone'

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

const FilterComponent = ({filterText, onFilter, onClear}) => (
  <>
    <TextField id="search" type="text" placeholder="Search" value={filterText} onChange={onFilter}/>
    <Button variant={'outlined'} color={'secondary'} onClick={onClear}>X</Button>
  </>
);
const AppointmentsList = props => {

  const [appointments, setAppointments] = useState([])
  const [selectedAppointment, setSelectedAppointment] = useState({})
  const [open, setOpen] = useState(false)

  const [filterText, setFilterText] = useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)


  const filteredItems = appointments.filter(item => {
    return (
      item.patientFirstName.toLowerCase().includes(filterText.toLowerCase())
      || item.therapistFirstName.toLowerCase().includes(filterText.toLowerCase())
      || item.patientLastName.toLowerCase().includes(filterText.toLowerCase())
      || item.therapistLastName.toLowerCase().includes(filterText.toLowerCase())
    )
  });

  async function getData(url = '') {

    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      }

    })
    return response.json(); // parses JSON response into native JavaScript objects
  }


  const handleClose = (event) => {
    setOpen(!event)
  }

  const getAppointments = () => {
    const timezone = moment.tz.guess();
    getData(`https://${API_URL}/api/admin/appointments?timezone=${timezone}`)
      .then(data => {
        setAppointments(data.content);
      });
  }

  useEffect(() => {

    getAppointments()
  }, [])

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return <>
      <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear}
                       filterText={filterText}/>
    </>
  }, [filterText, resetPaginationToggle]);

  const columns =
    [

      {
        name: 'Patient\'s First Name',
        selector: 'patientFirstName'
      },
      {
        name: 'Patient\'s Last Name',
        selector: 'patientLastName'
      },
      {
        name: 'Therapist\'s First Name',
        selector: 'therapistFirstName'
      },
      {
        name: 'Therapist\'s Last Name',
        selector: 'therapistLastName'
      },
      {
        name: 'Session Type',
        selector: 'sessionType',
        sortable: true
      },
      {
        name: 'Appointment Date',
        selector: 'appointmentDate',
        sortable: true
      },
      {
        name: 'Start Time',
        selector: 'startTime',
      },
      {
        name: 'End Time',
        selector: 'endTime',
      }

    ];
  const handleClickedPatient = (event) => {
    setSelectedAppointment(event);
    setOpen(true);
  }
  return <>
    <Box mb={4}>
      <DataTable
        pagination={true}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        paginationPerPage={10}
        title="Patient List"
        columns={columns}
        data={filteredItems}
        pointerOnHover={true}
        noHeader={true}
        highlightOnHover={true}
        pagination
        paginationResetDefaultPage={resetPaginationToggle}
      />
      {open && <AppointmentsModal patientDetails={selectedAppointment} closedModal={handleClose}/>}
    </Box>
  </>
}

export default AppointmentsList;