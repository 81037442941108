import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {AccountCircle} from '@material-ui/icons'
import {Box, Select, Typography} from '@material-ui/core'
import {API_URL} from 'config'
import SelectInput from '@material-ui/core/Select/SelectInput'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    outline: 'none'
  },
}));

export default function MatchedModal(props) {
  const {matchedDetails, closedModal,therapists} = props;
  const classes = useStyles();

  const [open, setOpen] = useState(true);
  const [selectedTherapist,setSelectedTherapist] = useState([]);
  const [disableSelection,setDisableSelection] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    closedModal(true);
  };

    const handleSelectedPatient = (event) => {
      console.log(event);
      if (event.length >3)
        setDisableSelection(true);
    }

  return (
    <div>
      <button type="button" onClick={handleOpen}>
        react-transition-group
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography variant={'h4'} id="transition-modal-title" color={'secondary'}>Suggest Therapists
              ({matchedDetails.id})</Typography>
            <Box id="transition-modal-description">
              <Box style={{textAlign: 'center'}}><AccountCircle fontSize={'large'}/></Box>
              <Autocomplete
                id="combo-box-demo"
                multiple
                options={therapists}
                getOptionLabel={option => option.firstName + ' ' + option.lastName}
                style={{ width: 300 }}
                disabled={disableSelection}
                // onSelect={handleSelectedPatient}
                onChange={handleSelectedPatient}
                renderInput={params => <TextField {...params} label="Therapist" variant="outlined" />}
              />
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}