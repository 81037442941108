import React from "react";
import MainNavBar from "components/UIElements/MainNavBar";
import ThemeConfig from "Theme/ThemeConfig";
import FooterWidget from "components/UIElements/FooterWidget";
import Footer from "components/UIElements/Footer";

import { ThemeProvider } from "@material-ui/core/styles";

const Faq = props => {
    return (
        <ThemeProvider theme={ThemeConfig}>
            <MainNavBar backgroundColor="transparent" />
            <FooterWidget backgroundColor="#ffffff" />
            <Footer backgroundColor="#ffffff" />
        </ThemeProvider>
    );
};

export default Faq;
