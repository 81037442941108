import React from "react";
import {  Grid, Box, Typography } from "@material-ui/core";
import Illustrations from "assets/IllustrationsList";

export const SuccessMessage = props => {
  const { user } = props;
  return (
    <>
      <Grid
        container
        direction="row"
        alignContent="flex-start"
        justify="center"
        alignItems="flex-start"
      >
        <Grid item>
          <img src={Illustrations.happy} width={"400px"} alt="Activation Art" />
        </Grid>
      </Grid>
      <Grid item>
        <Box>
          <Typography color="secondary" variant="h5" align="center" style={{fontWeight:'bolder'}}>
          Thank you, {user}!
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box>
          <Typography color="primary" variant="body2" align="center">
            Your account has been activated successfully
          </Typography>
        </Box>
      </Grid>
    </>
  );
};
