import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {AccountCircle} from '@material-ui/icons'
import {Box,Typography} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline :'none'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    outline :'none'
  },
}));

export default function PatientModal(props) {
  const {patientDetails, closedModal} = props;
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    closedModal(true);
  };

  return (
    <div>
      <button type="button" onClick={handleOpen}>
        react-transition-group
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography variant={'h4'} id="transition-modal-title" color={'secondary'}>Patient's Details ({patientDetails.username})</Typography>
            <Box id="transition-modal-description">
              <Box style={{textAlign:'center'}}><AccountCircle fontSize={'large'}/></Box>
              <Box><Typography variant={'subtitle2'}>Firstname </Typography></Box>
              <Box mb={2}><Typography variant={'subtitle1'}>{patientDetails.firstName}</Typography></Box>
              <Box><Typography variant={'subtitle2'}>Lastname </Typography></Box>
              <Box mb={2}><Typography variant={'subtitle1'}>{patientDetails.lastName}</Typography></Box>
              <Box><Typography variant={'subtitle2'}>City </Typography></Box>
              <Box mb={2}><Typography variant={'subtitle1'}>{patientDetails.city}</Typography></Box>
              <Box><Typography variant={'subtitle2'}>Contact Phone </Typography></Box>
              <Box mb={2}><Typography variant={'subtitle1'}>{patientDetails.contactPhone}</Typography></Box>
              <Box><Typography variant={'subtitle2'}>Zip Code </Typography></Box>
              <Box mb={2}><Typography variant={'subtitle1'}>{patientDetails.zipCode}</Typography></Box>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}