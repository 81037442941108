import React, {useEffect, useState, useMemo, useCallback} from 'react'
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import {API_URL} from 'config'
import {Box, Button} from '@material-ui/core'
import TherapistModal from 'pages/Admin/Therapist/TherapistModal'


const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

const FilterComponent = ({filterText, onFilter, onClear}) => (
  <>
    <TextField id="search" type="text" placeholder="Search" value={filterText} onChange={onFilter}/>
    <Button variant={'outlined'} color={'secondary'} onClick={onClear}>X</Button>
  </>
);
const TherapistList = props => {

  const [therapists, setTherapists] = useState([]);
  const [selectedTherapist, setSelectedTherapist] = useState({});
  const [open, setOpen] = useState(false)

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);


  const filteredItems = therapists.filter(item => {
    return (
      item.firstName.toLowerCase().includes(filterText.toLowerCase())
      || item.lastName.toLowerCase().includes(filterText.toLowerCase())
      || item.username.toLowerCase().includes(filterText.toLowerCase())
    )
  });

  async function getData(url = '') {

    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      }

    });
    return response.json(); // parses JSON response into native JavaScript objects
  }


  const handleClose = (event) => {
    setOpen(!event);
  }

  const getPatients = () => {
    getData(`https://${API_URL}/api/admin/therapists`)
      .then(data => {
        setTherapists(data.content);
      });
  }

  useEffect(() => {
    getPatients();
  }, [])

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return <>
      <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear}
                       filterText={filterText}/>
    </>
  }, [filterText, resetPaginationToggle]);

  const columns =
    [
      {
        name: 'Username',
        selector: 'username',
        sortable: true
      },
      {
        name: 'First Name',
        selector: 'firstName'
      },
      {
        name: 'Last Name',
        selector: 'lastName'
      }
    ];
  const handleClickedPatient = (event) => {
    setSelectedTherapist(event);
    setOpen(true);
  }
  return <>
    <Box mb={4}>
      <DataTable
        pagination={true}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        paginationPerPage={10}
        title="Patient List"
        columns={columns}
        data={filteredItems}
        pointerOnHover={true}
        noHeader={true}
        highlightOnHover={true}
        onRowClicked={handleClickedPatient}
        pagination
        paginationResetDefaultPage={resetPaginationToggle}
      />
      {open && <TherapistModal patientDetails={selectedTherapist} closedModal={handleClose}/>}
    </Box>
  </>
}

export default TherapistList;