import React from "react";
import MainNavBar from "components/UIElements/MainNavBar";
import ThemeConfig from "Theme/ThemeConfig";
import FooterWidget from "components/UIElements/FooterWidget";
import Footer from "components/UIElements/Footer";
import { ThemeProvider } from "@material-ui/core/styles";
import { ActivateToken } from "./ActivateToken";

export const Verification = props => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const user = urlParams.get("user");
  const token = urlParams.get("token");
  return (
    <ThemeProvider theme={ThemeConfig}>
      <MainNavBar backgroundColor="transparent" />
      <ActivateToken user={user} token={token} />
      <FooterWidget backgroundColor="#ffffff" />
      <Footer backgroundColor="#ffffff" />
    </ThemeProvider>
  );
};
