import React, {useState, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {Container, Box} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import {ReactComponent as LogoWarm} from 'assets/logos/logo-warm.png';
import clsx from 'clsx';
import ListItemIcon from '@material-ui/core/ListItemIcon'
import {Group, PermContactCalendar, PlaylistAddCheck, SupervisorAccount} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
    color: '#97a49a'
  },
  buttonAction: {
    boxShadow: '0 2px 4px 0 rgba(74, 74, 74, 0.4)',
    backgroundColor: '#97a49a',
    color: '#ffffff'
  },
  list: {
    width: 250
  },
  fullList: {
    width: 'auto'
  }
}));

export default function AdminNavBar(props) {
  const {backgroundColor, page, isLogin} = props;
  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const history = useHistory();

  const handleLinkClick = page => {
    history.push(`/${page}`);
  };

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen(false)
  };

  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom'
      })}
      style={{color: '#ffffff'}}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Divider/>
      <List>
        <ListItem button key={'patients'}>
          <ListItemIcon><Group/></ListItemIcon>
          <ListItemText primary={'Patients List'}/>
        </ListItem>
        <ListItem button key={'therapists'}>
          <ListItemIcon><SupervisorAccount/></ListItemIcon>
          <ListItemText primary={'Therapists List'}/>
        </ListItem>

      </List>
      <Divider/>
      <List>
        <ListItem button key={'matched'}>
          <ListItemIcon><PlaylistAddCheck/></ListItemIcon>
          <ListItemText primary={'Matched Requests'}/>
        </ListItem>
        <ListItem button key={'completed'}>
          <ListItemIcon><PermContactCalendar/></ListItemIcon>
          <ListItemText primary={'Completed Appointments'}/>
        </ListItem>
      </List>
      <Divider color={'#fffff'}/>
    </div>
  );


  const logoutHandler = () => {
    localStorage.removeItem('access_token')
  }
  return (
    <Box style={{backgroundColor: backgroundColor}} pt={5}>

      <Container>

        <AppBar color={'transparent'} position="static">
          <Toolbar disableGutters={true}>
            <Typography variant="h6" className={classes.title}>
              <a href="/admin">
                <LogoWarm/>
              </a>
            </Typography>
            <Hidden xsDown smDown>
              {isLogin &&
              <>
                <Button
                  color="primary"
                  variant={page === 'patientslist' ? 'contained' : 'outlined'}
                  href="/admin/patients"
                  startIcon={<Group/>}
                  fullWidth
                >
                  Patients
                </Button>
                < Button
                  color="primary"
                  variant={page === 'therapistslist' ? 'contained' : 'outlined'}
                  href="/admin/therapists"
                  startIcon={<SupervisorAccount/>}
                  fullWidth
                >
                  Therapists
                </Button>
                <Button
                  color="primary"
                  variant={page === 'matched' ? 'contained' : 'outlined'}
                  href="/admin/matched"
                  startIcon={<PlaylistAddCheck/>}
                  fullWidth
                >
                  Matched Requests
                </Button>
                <Button
                  color="primary"
                  variant={page === 'appointments' ? 'contained' : 'outlined'}
                  href="/admin/appointments"
                  startIcon={<PermContactCalendar/>}
                  fullWidth
                >
                  Appointments
                </Button>
              </>
              }

              {isLogin &&
              <Button
                color="secondary"
                variant="contained"
                href="/admin"
                style={{color: '#ffffff', minWidth: '200px'}}
                onClick={logoutHandler}
              >
                Logout
              </Button>
              }
            </Hidden>

            {/* Mobile Menu*/}
            <Hidden only={['xl', 'lg', 'md']}>
              <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
                style={{marginTop: '-4px'}}
              >
                <MenuIcon/>
              </Button>
              <Drawer open={open} anchor={'right'} onClose={toggleDrawer('right', false)}>
                {list('right')}
              </Drawer>
            </Hidden>
          </Toolbar>
        </AppBar>
      </Container>
    </Box>
  );
}
