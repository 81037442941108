import React from "react";

// Banner Section *
import BannerSection from "components/VisualComponents/BannerSection";

import Illustrations from "assets/IllustrationsList";
import Backgrounds from "assets/BackgroundList";

const CouncelorBanner = props => {
  return (
    <BannerSection
      sectionTitle="Join our coach network"
      sectionTitleVariant="h4"
      sectionContent="Sign up today!"
      sectionFontWeight="400"
      sectionTitleFontSize="36px"
      buttonVariant="contained"
      buttonColor="secondary"
      buttonLabel="Learn more"
      buttonHref="coach/signup"
      sectionImage={Illustrations.reading}
      sectionImageAlign="right"
      backgroundImage={Backgrounds.light}
      showTerms={false}
    />
  );
};

export default CouncelorBanner;
