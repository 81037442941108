import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";

import {  makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import { API_URL } from "config";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Typography, Button, Box, Container, Fade } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  },
  label: {
    fontSize: "11px",
    color: theme.palette.secondary.main,
    marginLeft: "10px",
    marginBottom: "2px"
  },
  absoluteProgress: {
    position: "absolute"
  },
  successSnackbar: {
    backgroundColor: "#97a49a"
  },
  warningSnackbar: {
    backgroundColor: "#df8166"
  },
  iconRoot: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  icon: {
    borderRadius: "3px",
    boxShadow: "inset 0 -2px 4px 0 #ffffff, inset 0 2px 4px 0 #4a4a4a66",
    backgroundColor: "#eeece7",
    width: 20,
    height: 20,

    "input:hover ~ &": {
      backgroundColor: "#df8166"
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)"
    }
  },
  checkedIcon: {
    backgroundColor: "#df8166",
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      color: "red",
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    "input:hover ~ &": {
      backgroundColor: "#9C5A47"
    }
  },
  inputBox: {
    borderRadius: 320,
    position: "relative",
    backgroundColor: "#eeece7",
    border: "1px solid #ced4da",
    fontSize: 16,
    marginBottom: "20px",
    boxShadow: "inset 0 -2px 4px 0 #ffffff, inset 0 2px 4px 0 #4a4a4a66",
    paddinLeft: "40px",
    transition: theme.transitions.create(["border-color", "box-shadow"])
  }
}));

const SignupForm = props => {
  const { backgroundColor, backgroundImage } = props;

  const classes = useStyles();

  const [subscribeToMailList, setChecked] = useState(false);

  const [password,setPassword] = useState("");
  const [passwordRetype,setPasswordRetype] = useState("");
  const [username,setUsername] = useState("");
  const [email, setEmail] = useState("");

  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackSeverity, setSnackSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const [messageColor, setMessageColor] = useState(classes.successSnackbar);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const toggleChecked = () => {
    setChecked(prev => !prev);
  };

  const handleUsernameChange = event => {
    setUsername(event.target.value);
  };

  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = event => {
    setPassword(event.target.value);
  };

  const handlePasswordRetypeChange = event => {
    setPasswordRetype(event.target.value);
  };


  const sendEmail = e => {
    const passwordPattern = new RegExp("^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^!&*+=]).*$");

    if (password !== passwordRetype)
    {
      setOpen(true);
      setSnackSeverity("warning");
      setSnackMessage("Your passwords are not matched");
      setMessageColor(classes.warningSnackbar);
    }
    else if (password.length <8)
    {
      setOpen(true);
      setSnackSeverity("warning");
      setSnackMessage("Your password must have at least 8 characters");
      setMessageColor(classes.warningSnackbar);
    }
    else if (!passwordPattern.test(password))
    {
      setOpen(true);
      setSnackSeverity("warning");
      setSnackMessage("Your password must have one capital letter, one special character and one number ");
      setMessageColor(classes.warningSnackbar);
    }
    else {
      const payload = {
        subject: "Holistikally | Joined the waitlist",
        firstName: " ",
        lastName: " ",
        email: email,
        username:username,
        password: password,
        type: "patient",
        subscribeToMailList: true
      };
      setLoading(prevLoading => !prevLoading);

      fetch(`https://${API_URL}/submitinterest`, {
        method: "post",
        body: JSON.stringify(payload),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        }
      })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.status) {
              setSnackSeverity("warning");
              setSnackMessage(data.message);
              setMessageColor(classes.warningSnackbar);
            } else {
              setSnackSeverity("success");
              setMessageColor(classes.successSnackbar);
              setSnackMessage("You have signed up successfully");
            }
            setUsername("");
            setPassword("");
            setPasswordRetype("");
            setEmail("");
            toggleChecked();
            setOpen(true);
            setLoading(prevLoading => !prevLoading);
          })
          .catch(function () {
            setOpen(true);
            setSnackSeverity("warning");
            setSnackMessage("Your email wasn't send due to an error from our side");
            setMessageColor(classes.warningSnackbar);
          });
    }
    e.preventDefault();

  };

  return (
    <>
      <Box
        style={{
          background: backgroundColor
            ? backgroundColor
            : `url(${backgroundImage}) no-repeat center center fixed`,
          backgroundSize: "cover"
        }}
        pb={10}
        pt={4}
      >
        <Container>
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            justify="flex-start"
          >
            <Grid item lg={10} md={10} sm={8} xs={12}>
              <Box display="flex" mt={0} justifyContent="left">
                <Typography align="left" variant="h4">
                  Sign Up!
                </Typography>
              </Box>
              <Box display="flex" mt={2} justifyContent="left">
                <Typography
                  align="left"
                  style={{ fontSize: "24px", color: "#4a4a4a" }}
                >
                  Join our waitlist to get access to our app as soon as it is
                  available, and to receive updates and launch details!
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justify="flex-start"
            alignContent="center"
          >
            <Grid item lg={8} md={8} sm={8} xs={12}>
              <Box mt={4}>
                <form onSubmit={sendEmail}>
                  <Box className={classes.inputBox}>
                    <TextField
                        label="Username"
                        InputProps={{
                          disableUnderline: true
                        }}
                        style={{ marginLeft: "16px", maxWidth: "320px" }}
                        fullWidth
                        required
                        onChange={handleUsernameChange}
                        value={username}
                    />
                  </Box>
                  <Box className={classes.inputBox}>
                    <TextField
                        label="Email"
                        InputProps={{
                          disableUnderline: true
                        }}
                        style={{ marginLeft: "16px", maxWidth: "320px" }}
                        fullWidth
                        required
                        onChange={handleEmailChange}
                        value={email}
                        type={"email"}
                    />
                  </Box>
                  <Box className={classes.inputBox}>
                    <TextField
                        label="Password"
                        InputProps={{
                          disableUnderline: true
                        }}
                        style={{ marginLeft: "16px", maxWidth: "320px" }}
                        fullWidth
                        required
                        onChange={handlePasswordChange}
                        value={password}
                        type={"password"}
                    />
                  </Box>

                  <Box className={classes.inputBox}>
                    <TextField
                        label="Re-enter Password"
                        InputProps={{
                          disableUnderline: true
                        }}
                        style={{ marginLeft: "16px", maxWidth: "320px" }}
                        fullWidth
                        required
                        onChange={handlePasswordRetypeChange}
                        value={passwordRetype}
                        type={"password"}
                    />
                  </Box>

                  <Box ml={2} mb={4}>
                    <Box mb={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.iconRoot}
                            disableRipple
                            color="default"
                            checkedIcon={
                              <span
                                className={clsx(
                                  classes.icon,
                                  classes.checkedIcon
                                )}
                              />
                            }
                            icon={<span className={classes.icon} />}
                            inputProps={{ "aria-label": "decorative checkbox" }}
                            checked={subscribeToMailList}
                            onChange={toggleChecked}
                            {...props}
                          />
                        }
                        label={
                          <Box ml={2}>
                            <Typography>
                              By signing up, you agree to our  <br />
                              <a
                                href="#"
                                style={{
                                  textDecoration: "none",
                                  color: "#df8166"
                                }}
                              >
                                Terms of Use
                              </a>{" "}
                              &{" "}
                              <a
                                href="#"
                                style={{
                                  textDecoration: "none",
                                  color: "#df8166"
                                }}
                              >
                                Privacy Policy
                              </a>
                              <br/>
                              and to receiving email and updates.
                            </Typography>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                  <Box mt={1}>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={!subscribeToMailList || loading}
                      fullWidth
                      size="large"
                      style={{ height: "60px" }}
                      type="submit"
                    >
                      Join Waitlist
                      <div className={classes.absoluteProgress}>
                        <Fade
                          in={loading}
                          style={{
                            transitionDelay: loading ? "800ms" : "0ms"
                          }}
                          unmountOnExit
                        >
                          <CircularProgress color="secondary" />
                        </Fade>
                      </div>
                    </Button>
                  </Box>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackSeverity}
          className={messageColor}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SignupForm;
