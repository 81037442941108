import React from "react";
import Grid from "@material-ui/core/Grid";

import { Typography, Box, Container } from "@material-ui/core";
import ToggleList from "components/VisualComponents/ToggleList";
const toggleListItems = [
  {
    title: "More clients, more money, less time",
    content:
      "with options to text, call or video chat, you can help more clients and earn more money over short periods of time!"
  },
  {
    title: "Flexible hours and location",
    content: "you can work with patients from the comfort of your home !"
  },
  {
    title: "Secure compliant platform",
    content:
      "mhCare is designed to enable secure and private HIPAA compliant communications between coachees-seekers and life coaches."
  }
];
const BannerWithTitles = props => {
  const {
    sectionTitle,
    sectionTitleVariant,
    sectionTitleColor,
    sectionImage,
    sectionFontWeight,
    backgroundColor,
    backgroundImage
  } = props;
  return (
    <>
      <Box
        style={{
          background: backgroundColor
            ? backgroundColor
            : `url(${backgroundImage}) no-repeat center center fixed`,
          backgroundSize: "cover"
        }}
        pb={10}
        pt={10}
      >
        <Container fixed>
          <Grid
            container
            direction="row"
            justify="center"
            alignContent="flex-start"
            alignItems="stretch"
          >
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <Box mt={"15%"}>
                <img src={sectionImage} width="70%" alt="section image" />
              </Box>
            </Grid>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <Box mt={"10%"}>
                <Box>
                  <Typography
                    variant={sectionTitleVariant}
                    color={sectionTitleColor}
                    style={{ fontWeight: sectionFontWeight }}
                  >
                    {sectionTitle}
                  </Typography>
                </Box>
                <ToggleList toggleListItems={toggleListItems} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default BannerWithTitles;
