import React, {useEffect, useState} from 'react';
import {ThemeProvider} from '@material-ui/core/styles'
import {useLocation, useParams, withRouter} from 'react-router-dom'
import AdminNavBar from 'components/UIElements/AdminNavBar';
import ThemeConfig from 'Theme/ThemeConfig';
import FooterWidget from 'components/UIElements/FooterWidget';
import Footer from 'components/UIElements/Footer';
import AdminLoginForm from 'pages/Admin/AdminLoginForm';
import {Container,Grid} from '@material-ui/core'
import AdminSidebar from 'pages/Admin/AdminSidebar'
import CheckToken from 'pages/Admin/CheckToken'


const AdminLogin = props => {
  const [loginState,setLoginState] = useState(false)
  const access_token = localStorage.getItem('access_token');
  useEffect(()=>{
    if (access_token)
      setLoginState(true)
  })
  return <>
    <CheckToken token = {access_token}/>
    <ThemeProvider theme={ThemeConfig}>
      <AdminNavBar backgroundColor="transparent" isLogin = {loginState}/>
      <Container>
        <Grid container alignContent={'center'} justify={'center'} alignItems={'center'}>
            <Grid item lg={4} sm={6} xs={12}>
              {!loginState && <AdminLoginForm/> }
            </Grid>
        </Grid>
      </Container>
      <FooterWidget backgroundColor="#ffffff"/>
      <Footer backgroundColor="#ffffff"/>
    </ThemeProvider>

  </>
}


export default AdminLogin;