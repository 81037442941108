import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';

import {makeStyles} from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import {API_URL} from 'config';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Typography, Button, Box, Container, Fade} from '@material-ui/core';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  label: {
    fontSize: '11px',
    color: theme.palette.secondary.main,
    marginLeft: '10px',
    marginBottom: '2px'
  },
  absoluteProgress: {
    position: 'absolute'
  },
  successSnackbar: {
    backgroundColor: '#97a49a'
  },
  warningSnackbar: {
    backgroundColor: '#df8166'
  },
  iconRoot: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    borderRadius: '3px',
    boxShadow: 'inset 0 -2px 4px 0 #ffffff, inset 0 2px 4px 0 #4a4a4a66',
    backgroundColor: '#eeece7',
    width: 20,
    height: 20,

    'input:hover ~ &': {
      backgroundColor: '#df8166'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  },
  checkedIcon: {
    backgroundColor: '#df8166',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      color: 'red',
      backgroundImage:
        'url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\'%3E%3Cpath' +
        ' fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 ' +
        '1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z\' fill=\'%23fff\'/%3E%3C/svg%3E")',
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#9C5A47'
    }
  },
  inputBox: {
    borderRadius: 320,
    position: 'relative',
    backgroundColor: '#eeece7',
    border: '1px solid #ced4da',
    fontSize: 16,
    marginBottom: '20px',
    boxShadow: 'inset 0 -2px 4px 0 #ffffff, inset 0 2px 4px 0 #4a4a4a66',
    paddinLeft: '40px',
    transition: theme.transitions.create(['border-color', 'box-shadow'])
  }
}));

const AdminLoginForm = props => {
  const classes = useStyles();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState('success');
  const [loading, setLoading] = useState(false);
  const [messageColor, setMessageColor] = useState(classes.successSnackbar);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const loginHandler = (e) => {
    const payload = {
      username: username,
      password: password,
    };
    setLoading(prevLoading => !prevLoading);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    }

    fetch(`https://${API_URL}/token/jwt`, requestOptions)
      .then(function (response) {
        return response.json();
        console.log(response);
      })
      .then(function (data) {
        console.log(data)
        if (data.status) {
          setOpen(true);
          setSnackMessage(data.message);
          setMessageColor(classes.warningSnackbar);
          setLoading(prevLoading => !prevLoading);
        } else {
          setOpen(true);
          setSnackMessage('Success');
          setMessageColor(classes.successSnackBar);
          setLoading(prevLoading => !prevLoading);
          localStorage.setItem('access_token', data.token);
          window.location.reload();
        }
      })
      .catch(function (data) {
        setOpen(true);
        setSnackSeverity('warning');
        setSnackMessage(data.message);
        setMessageColor(classes.warningSnackbar);
      });

    e.preventDefault();
  }

  const handleUsernameChange = event => {
    setUsername(event.target.value);
  }

  const handlePasswordChange = event => {
    setPassword(event.target.value);
  }
  return <>


    <Box mt={4} style={{width: '100%'}}>
      <form onSubmit={loginHandler}>
        <Grid container direction={'row'} justify={'center'} alignItems={'center'} alignContent={'center'}>
          <Grid item lg={12} xs={12} sm={12}>
            <Box className={classes.inputBox}>
              <TextField
                style={{marginLeft: '16px'}}
                label="Username"
                InputProps={{
                  disableUnderline: true
                }}
                fullWidth
                required
                onChange={handleUsernameChange}
                value={username}
                type={'text'}
              />
            </Box>
          </Grid>
          <Grid item lg={12} xs={12} sm={12}>
            <Box className={classes.inputBox}>
              <TextField
                style={{marginLeft: '16px'}}
                label="Password"
                InputProps={{
                  disableUnderline: true
                }}
                fullWidth
                required
                onChange={handlePasswordChange}
                value={password}
                type={'password'}
              />
            </Box>
          </Grid>
          <Grid item lg={12} xs={12} sm={12}>
            <Button
              color="primary"
              variant="contained"
              disabled={loading}
              fullWidth
              size="medium"
              style={{height: '60px'}}
              type="submit"
            >
              Login
              <div className={classes.absoluteProgress}>
                <Fade
                  in={loading}
                  style={{
                    transitionDelay: loading ? '800ms' : '0ms'
                  }}
                  unmountOnExit
                >
                  <CircularProgress color="secondary"/>
                </Fade>
              </div>
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>

    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity={snackSeverity}
        className={messageColor}
      >
        {snackMessage}
      </Alert>
    </Snackbar>


  </>
}

export default AdminLoginForm;