import React, {useEffect, useState} from 'react';
import {ThemeProvider} from '@material-ui/core/styles'
import AdminNavBar from 'components/UIElements/AdminNavBar';
import ThemeConfig from 'Theme/ThemeConfig';
import FooterWidget from 'components/UIElements/FooterWidget';
import Footer from 'components/UIElements/Footer';
import AdminLoginForm from 'pages/Admin/AdminLoginForm';
import {Container,Grid} from '@material-ui/core'
import CheckToken from 'pages/Admin/CheckToken'
import MatchedList from 'pages/Admin/Matched/MatchedList'


const MatchedView = props => {
  const [loginState,setLoginState] = useState(false)
  const access_token = localStorage.getItem('access_token');
  useEffect(()=>{
    if (access_token)
      setLoginState(true)

  })
  return <>
    <CheckToken token = {access_token}/>
    <ThemeProvider theme={ThemeConfig}>
      <AdminNavBar backgroundColor="transparent" page={'matched'} isLogin = {loginState}/>
      <Container>
        <Grid container alignContent={'center'} justify={'center'} alignItems={'center'}>
          <Grid item lg={4} sm={6} xs={12}>
            {!loginState && <AdminLoginForm/> }
          </Grid>
        </Grid>
        {loginState && <MatchedList/>}
      </Container>
      <FooterWidget backgroundColor="#ffffff"/>
      <Footer backgroundColor="#ffffff"/>
    </ThemeProvider>

  </>
}


export default MatchedView;