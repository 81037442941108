import React from "react";
import Grid from "@material-ui/core/Grid";

import { Box, Container } from "@material-ui/core";
import UserSignupForm from "pages/Signup/User/UserSignupForm";
import ContactUsForm from "pages/ContactUs/ContactUsForm";
const BannerSection = props => {
  const { sectionImage, backgroundColor, backgroundImage, showForm } = props;

  return (
    <>
      <Box
        style={{
          background: backgroundColor
            ? backgroundColor
            : `url(${backgroundImage}) no-repeat center center fixed`,
          backgroundSize: "cover"
        }}
        pb={10}
        pt={8}
      >
        <Container>
          <Grid
            item
            cs={4}
            container
            direction="row"
            alignItems="center"
            justify="center"
            alignContent="center"
          >
            <Grid item lg={6} md={6} xs={12}>
              <Box>
                <img src={sectionImage} width="80%" alt="Section's Clipart" />
              </Box>
            </Grid>
            <Grid item lg={6} md={6}>
              {showForm === "userSignup" && <UserSignupForm />}
              {showForm === "contactForm" && <ContactUsForm />}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default BannerSection;
