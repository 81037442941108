import React from "react";
import ThemeConfig from "Theme/ThemeConfig";
import { ThemeProvider } from "@material-ui/core/styles";
import HomeBanner from "pages/Home/HomeBanner";
import MobileBanner from "pages/Home/MobileBanner";
import CouncelorBanner from "pages/Home/CouncelorBanner";
import CardsGrid from "pages/Home/CardsGrid";
import HomeTestimonials from "./HomeTestimonials";
import FooterWidget from "components/UIElements/FooterWidget";
import Footer from "components/UIElements/Footer";

const Home = props => {
  return (
    <ThemeProvider theme={ThemeConfig}>
        <HomeBanner />
        <CardsGrid />
        <MobileBanner />
        <HomeTestimonials />
        <CouncelorBanner />
        <FooterWidget backgroundColor="#ffffff" />
        <Footer backgroundColor="#ffffff" />
    </ThemeProvider>
  );
};

export default Home;
