import React from "react";

// Banner Section *
import MockupSection from "components/VisualComponents/MockupSection";

// Mockups Design *
import MockupsList from "assets/MockupsList";

const MobileBanner = props => {
  return (
    <MockupSection
      sectionTitle="Why people love us"
      sectionTitleVariant="h4"
      sectionTitleColor="primary"
      sectionImage={MockupsList.mockup3}
      sectionImageAlign="left"
      buttonVariant="contained"
      buttonColor="primary"
      backgroundColor="transparent"
      showAppStores={true}
    />
  );
};

export default MobileBanner;
