import React from "react";
import Grid from "@material-ui/core/Grid";
import { Typography, Button, Box, Container } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import MainNavBar from "components/UIElements/MainNavBar";
const BannerSection = props => {
  const {
    sectionTitle,
    sectionTitleVariant,
    sectionTitleFontSize,
    sectionContent,
    sectionImage,
    sectionImageAlign,
    sectionFontWeight,
    buttonLabel,
    buttonVariant,
    buttonHref,
    buttonColor,
    backgroundColor,
    backgroundImage,
    showTerms,
    includeNavBar
  } = props;
  return (
    <>
      <Box
        style={{
          background: backgroundColor
            ? backgroundColor
            : `url(${backgroundImage}) no-repeat center center fixed`,
          backgroundSize: "cover"
        }}
        pb={5}
      >
        {includeNavBar && <MainNavBar />}
        <Box className={"body_wrapper"}>
        <Container>
          <Grid
            container
            direction={sectionImageAlign === "right" ? "row" : "row-reverse"}
            justify="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <Box mt={10}>
                <Typography
                  variant={sectionTitleVariant}
                  style={{
                    fontWeight: sectionFontWeight,
                    fontSize: sectionTitleFontSize
                  }}
                >
                  {sectionTitle}
                </Typography>
              </Box>
              <Box mt={2} maxWidth={480}>
                <Typography variant="subtitle1">{sectionContent}</Typography>
              </Box>
              <Box mt={2}>
                <Button
                  variant={buttonVariant}
                  color={buttonColor}
                  href={buttonHref}
                  style={{ paddingLeft: "60px", paddingRight: "60px" }}
                >
                  {buttonLabel}
                </Button>
              </Box>
              {showTerms && (
                <Box mt={2}>
                  <Typography variant="subtitle2">
                    Easy setup · Cancel any time. See our{" "}
                    <Link href="#">Terms of Use</Link> &{" "}
                    <Link href="#">Privacy Policy</Link>
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <Box mt={8}>
                <img src={sectionImage} width="100%" alt="Section's Clipart" />
              </Box>
            </Grid>
          </Grid>
        </Container>
        </Box>
      </Box>
    </>
  );
};

export default BannerSection;
