import React from "react";
import Typography from "@material-ui/core/Typography";
import { Container, Box, Grid } from "@material-ui/core";
import { ReactComponent as Logo } from "assets/logos/logo.svg";

export default function FooterWidget(props) {
  const { backgroundColor } = props;

  return (
    <Box
      style={{
        backgroundColor: backgroundColor,
        bottom: 0
      }}
      pb={4}
    >
     <Box className={"body_wrapper"}>
      <Container fixed>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignContent="center"
          alignItems="flex-start"
        >
          <Grid item lg={12} xs={12} sm={12} md={12}>
            <Box mt={12}>
              <Typography
                variant="h6"
                style={{ color: "#97a49a", fontSize: "24px" }}
              >
                <Logo />
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <Box mt={6} mb={2}>
              <Typography
                style={{
                  fontWeight: "normal",
                  fontSize: "18px",
                  color: "#2b292d"
                }}
              >
                Your identity matters to your health
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <Box mb={4}>
              <Typography
                style={{
                  color: "#4a4a4a",
                  lineHeight: "1.44",
                  fontSize: "14px",
                  letterSpacing: "normal"
                }}
              >
                  We are a team of life coaches, technologists and investors who are
                on a mission to democratize access to life coaching care using
                technology.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
        </Box>
    </Box>
  );
}
