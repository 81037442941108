import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import Illustrations from "assets/IllustrationsList";

export const ErrorMessage = props => {
  const { user, message } = props;
  return (
    <>
      <Grid
        container
        direction="row"
        alignContent="flex-start"
        justify="center"
        alignItems="flex-start"
      >
        <Grid item>
          <img src={Illustrations.sitting} alt="Activation Art" width={"400px"}/>
        </Grid>
      </Grid>
      <Grid item>
        <Box>
          <Typography color="secondary" variant="h5" align="center" style={{fontWeight:'bolder'}}>
            Sorry, {user}!
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box>
          <Typography color="primary" variant="body2" align="center">
            {message}
          </Typography>
        </Box>
      </Grid>
    </>
  );
};
