import React from "react";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import ToggleList from "components/VisualComponents/ToggleList";
import { Typography, Button, Box, Container } from "@material-ui/core";
import { ReactComponent as Hipaa } from "assets/misc/hipaa.svg";
import { ReactComponent as Ssl } from "assets/misc/ssl.svg";
const toggleItems = [
  {
    title: "Identity Sensitive Coaching works",
    content:
      "mhCare uniquely matches you up with life coaches who can identify with people with your identity blueprint and effectively treat them. our algorithm takes in account your faith background, culture, race and sexuality, among other identity markers. We find the best life coach for you!"
  },
  {
    title: "We make coaching flexible and convenient",
    content:
      "Got a busy schedule? No problem! Text, call or video chat with your life coach anywhere at any time. No need to physically commute or waste time getting to coaching appointments."
  },
  {
    title: "Affordability is a priority",
    content:
      "Save some cash and still get high quality care at affordable rates."
  },
  {
    title: "Your privacy is paramount",
    content:
      "mhCare is designed to enable secure and private communications between coachees-seekers and life coaches."
  }
];

const useStyles = makeStyles(theme => ({
  hipaaSSl: {
    [theme.breakpoints.up("lg")]: {
      marginTop: "-240px"
    },
    [theme.breakpoints.only("md")]: {
      marginTop: "-25px",
      textAlign: "center"
    }
  }
}));

const MockupSection = props => {
  const {
    sectionTitle,
    sectionTitleVariant,
    sectionTitleColor,
    sectionImage,
    sectionImageAlign,
    buttonLabel,
    buttonVariant,
    buttonHref,
    buttonColor,
    backgroundColor,
    backgroundImage
  } = props;
  const classes = useStyles();
  return (
    <>
      <Box
        style={{
          background: backgroundColor
            ? backgroundColor
            : `url(${backgroundImage}) no-repeat center center fixed`,
          backgroundSize: "cover"
        }}
        pb={10}
        pt={10}
      >
        <Box className={"body_wrapper"}>
        <Container>
          <Grid
            container
            direction={sectionImageAlign === "right" ? "row" : "row-reverse"}
            justify="center"
            alignContent="flex-start"
            alignItems="stretch"
          >
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <Box pt={"60px"}>
                <Box>
                  <Typography
                    variant={sectionTitleVariant}
                    color={sectionTitleColor}
                    style={{ fontWeight: "400", fontSize: "36px" }}
                  >
                    {sectionTitle}
                  </Typography>
                </Box>
                <ToggleList toggleListItems={toggleItems} />

                {/* Button */}
                {buttonHref && (
                  <Box mt={2}>
                    <Button
                      variant={buttonVariant}
                      color={buttonColor}
                      href={buttonHref}
                    >
                      {buttonLabel}
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>

            {/* Section Image */}

            <Grid item lg={6} xs={12} md={6}>
              <Hidden xsDown smDown>
                <Box mt={-4} mr={5}>
                  <img
                    src={sectionImage}
                    width="100%"
                    alt="Section's Clipart"
                  />
                </Box>
              </Hidden>
            </Grid>
          </Grid>
        </Container>
        </Box>
      </Box>
    </>
  );
};

export default MockupSection;
