import React, { useState, useEffect } from "react";
import { Container, Grid, Box, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { API_URL } from "config";
import { SuccessMessage } from "./SuccessMessage";
import { ErrorMessage } from "./ErrorMessage";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
  absoluteProgress: {
    position: "absolute"
  }
}));
export const ActivateToken = props => {
  const classes = useStyles();

  const { user, token } = props;

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const activateUser = () => {
    const payload = {
      user: `${user}`,
      token: `${token}`
    };
    //setLoading(prevLoading => !prevLoading);

    fetch(`https://${API_URL}/signup/verification`, {
      method: "post",
      body: JSON.stringify(payload),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      }
    })
      .then(function(response) {
        if (response.status === 200) {
          setLoading(false);
          setShowSuccess(true);
        }
        return response.json();
      })
      .then(function(data) {
        if (data.status) {
          setLoading(false);
          setShowError(true);
          setResponseMessage(data.message);
        }

        //setLoading(prevLoading => !prevLoading);
      })
      .catch(function() {});
  };

  useEffect(() => {
    activateUser();
  }, [user, token]);

  return (
    <Box pt={10} pb={10}>
      <Container>
        <Grid
          container
          direction="column"
          alignContent="center"
          justify="center"
        >
          <Grid item>
            <div className={classes.absoluteProgress}>
              <Fade
                in={loading}
                style={{
                  transitionDelay: loading ? "800ms" : "0ms"
                }}
                unmountOnExit
              >
                <CircularProgress color="secondary" />
              </Fade>
            </div>
          </Grid>
          {showSuccess && <SuccessMessage user={user} />}
          {showError && <ErrorMessage user={user} message={responseMessage} />}
        </Grid>
      </Container>
    </Box>
  );
};
