import React from "react";

// Banner Section *
import Testimonials from "components/VisualComponents/Testimonials";

import Illustrations from "assets/IllustrationsList";
import Backgrounds from "assets/BackgroundList";

const testimonialTexts = [
  {
    content:
      "Before mhCare, I was not able to find a coach who looked like me and shared my faith. mhCare matched me with a black, Christian woman coach, Carmen,  who understood nuances about my background immediately. I felt comfortable and didn't need to explain myself. I do enough of that at work.  mhCare gave me a safe space to be myself and get right to the heart of my issues.",
    user: "Candace",
    age: "31",
    category: "Black / Christian / Straight"
  },
  {
    content:
      "As a gay male executive, I'm usually traveling for work constantly.  I needed a flexible option to text or video chat with a coach. mhCare has been a gift; I've found my coach who is a gay man as well. It's so easy to communicate as and when we need to through the mhCare app",
    user: "Ahmed",
    age: "43",
    category: "Latin / Agnostic / Gay"
  },
  {
    content:
      "I love this app! I've been impressed by how easy it was for me to connect with my coach simply because we share so much in common. We have together created a plan that is a combo of texting, calls and video conferencing that works well for me. The awesome match and flexibility is really everything.",
    user: "Martin",
    age: "27",
    category: "Chinese / Atheist / Straight"
  }
];

const HomeTestimonials = props => {
  return (
    <Testimonials
      sectionTitle="What our users say"
      sectionTitleVariant="h5"
      testimonialsContent={testimonialTexts}
      backgroundColor="#ffffff"
    />
  );
};

export default HomeTestimonials;
