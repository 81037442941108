import React from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import ScrollToTopRoute from './ScrollToTopRoute';
import './App.css';

/*---pages ---*/

import Home from './pages/Home';
import About from './pages/About';
import ContactUs from './pages/ContactUs';
import UserSignup from 'pages/Signup/User';
import TherapistSignup from 'pages/Signup/Therapist';
import {Verification} from 'pages/Signup/Verification';
import Unsubscribe from 'pages/Unsubscribe';
import ShareWithAfriend
  from 'pages/ShareWithAfriend';
import Terms from 'pages/Terms';
import Policies from 'pages/Policies';
import Faq from 'pages/FAQ'
import NotFound from 'pages/NotFound'
import AdminLogin from 'pages/Admin'
import PatientsView from 'pages/Admin/Patient/PatientsView'
import TherapistView from 'pages/Admin/Therapist/TherapistView'
import MatchedView from 'pages/Admin/Matched/MatchedView'
import AppointmentsView from 'pages/Admin/Appointments/AppointmentsView'

function App() {
  return (
    <>
      <Router>
        <Switch>
          <ScrollToTopRoute
            exact={true}
            path={'/'}
            component={Home}
            pageTitle="Home"
          />
          <ScrollToTopRoute
            exact={true}
            path={'/about'}
            component={About}
            pageTitle={'About'}
          />
          <ScrollToTopRoute
            exact={true}
            path={'/user/signup'}
            component={UserSignup}
            pageTitle="User Signup"
          />
          <ScrollToTopRoute
            exact={true}
            path={'/faq'}
            component={Faq}
            pageTitle="Faq"
          />
          <ScrollToTopRoute
            exact={true}
            path={'/contactus'}
            component={ContactUs}
            pageTitle="Contact Us"
          />
          <ScrollToTopRoute
            exact={true}
            path={'/coach/signup'}
            component={TherapistSignup}
            pageTitle="Coach Signup"
          />
          <ScrollToTopRoute
            exact={true}
            path={'/signup/verification'}
            component={Verification}
            pageTitle="Mail Verification"
          />
          <ScrollToTopRoute
            exact={true}
            path={'/unsubscribe'}
            component={Unsubscribe}
            pageTitle="Unsubscribe"
          />
          <ScrollToTopRoute
            exact={true}
            path={'/sharewithafriend'}
            component={ShareWithAfriend}
            pageTitle="Share with a friend"
          />
          <ScrollToTopRoute
            exact={true}
            path={'/terms'}
            component={Terms}
            pageTitle="Terms & Conditions"
          />
          <ScrollToTopRoute
            exact={true}
            path={'/privacypolicy'}
            component={Policies}
            pageTitle="Privacy Policy"
          />
          <ScrollToTopRoute
            exact={true}
            path={'/admin'}
            component={AdminLogin}
            pageTitle="Admin Login"
          />
          <ScrollToTopRoute
            exact={true}
            path={'/admin/patients'}
            component={PatientsView}
            pageTitle="Patients List"
          />
          <ScrollToTopRoute
            exact={true}
            path={'/admin/therapists'}
            component={TherapistView}
            pageTitle="Therapists List"
          />
          <ScrollToTopRoute
            exact={true}
            path={'/admin/matched'}
            component={MatchedView}
            pageTitle="Matched Requests"
          />
          <ScrollToTopRoute
            exact={true}
            path={'/admin/appointments'}
            component={AppointmentsView}
            pageTitle="Appointments"
          />
          <ScrollToTopRoute
            exact={true}
            path={'*'}
            component={NotFound}
            pageTitle="Page Not Found"
          />

        </Switch>
      </Router>
    </>
  );
}

export default App;
