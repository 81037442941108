import React, { useState } from "react";
import {Container, Grid, Box, Typography, Fade, Button} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Illustrations from "assets/IllustrationsList";
import Logo from "assets/logos/logo.svg";
import { API_URL } from "config";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles(theme => ({

    root: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2)
        }
    },
    label: {
        fontSize: "11px",
        color: theme.palette.secondary.main,
        marginLeft: "10px",
        marginBottom: "2px"
    },
    absoluteProgress: {
        position: "absolute"
    },
    successSnackbar: {
        backgroundColor: "#97a49a"
    },
    warningSnackbar: {
        backgroundColor: "#df8166"
    },
    iconRoot: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    icon: {
        borderRadius: "3px",
        boxShadow: "inset 0 -2px 4px 0 #ffffff, inset 0 2px 4px 0 #4a4a4a66",
        backgroundColor: "#eeece7",
        width: 20,
        height: 20,

        "input:hover ~ &": {
            backgroundColor: "#df8166"
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)"
        }
    },
    checkedIcon: {
        backgroundColor: "#df8166",
        "&:before": {
            display: "block",
            width: 20,
            height: 20,
            color: "red",
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""'
        },
        "input:hover ~ &": {
            backgroundColor: "#9C5A47"
        }
    },
    inputBox: {
        borderRadius: 320,
        position: "relative",
        backgroundColor: "#eeece7",
        border: "1px solid #ced4da",
        fontSize: 16,
        marginBottom: "20px",
        boxShadow: "inset 0 -2px 4px 0 #ffffff, inset 0 2px 4px 0 #4a4a4a66",
        paddinLeft: "40px",
        transition: theme.transitions.create(["border-color", "box-shadow"])
    }
}));


const UnsubscribeForm = props =>{
    const classes = useStyles();
    const {uuid} = props;

    const [loading, setLoading] = useState(false);
    const [success,setSuccess] = useState(false);
    const [checked,setChecked] = useState(false);

    const unsubscribeUser = () => {
        const payload = {
            uuid: `${uuid}`
        };

        setLoading(true);

        fetch(`https://${API_URL}/submitinterest/unsubscribe`, {
            method: "post",
            body: JSON.stringify(payload),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        })
            .then(function(response) {
                if (response.status === 200) {
                    setLoading(false);
                    setChecked(false);
                    setSuccess(true);
                }
                return response.json();
            })
            .then(function(data) {
                if (data.status) {
                    setLoading(false);
                }
            })
            .catch(function() {});
    };

    const toggleChecked = () =>{
        setChecked(prev => !prev);
    };

    const handleClick = () =>{
        unsubscribeUser();
    }

    return(
        <Box>
            <Container style={{backgroundColor:"#f5f4f0",paddingTop:80,paddingBottom:100}}>
                <Grid container direction={"column"} justify={"center"} alignItems={"center"} alignContent={"center"}>
                    <Grid item lg={12}>
                        <img src = {Logo} width={"200px"} alt="Logo"/>
                    </Grid>
                    <Grid item lg={12}>
                        <Box mt={4}>
                        <img src={Illustrations.outOfTheBox} alt="Unsubscribe Clipart" width={"200px"}/>
                        </Box>
                    </Grid>
                    {!success && (
                        <>
                    <Grid item lg={12}>
                        <Box mt={2}>
                        <Typography variant={"h5"} color={"secondary"} style={{fontWeight:"600"}}>Unsubscribe</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box mt={1}>
                            <Typography align={"center"}>We are sad to see you go! Remember that you are always welcome to come back to our community </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box mt={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        className={classes.iconRoot}
                                        disableRipple
                                        color="default"
                                        checkedIcon={
                                            <span
                                                className={clsx(
                                                    classes.icon,
                                                    classes.checkedIcon
                                                )}
                                            />
                                        }
                                        icon={<span className={classes.icon} />}
                                        inputProps={{ "aria-label": "decorative checkbox" }}
                                        checked={checked}
                                        onChange={toggleChecked}
                                        {...props}
                                    />
                                }
                                label={
                                    <Box ml={2}>
                                        <Typography>
                                            I would like to unsubscribe from receiving newsletter updates
                                        </Typography>
                                    </Box>
                                }
                            />
                        </Box>
                    </Grid>
                    <Grid item lg={12}>
                        <Box mt={1}>
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={!checked || loading}
                                fullWidth
                                style={{ height: "40px", width:"190px" }}
                                type="submit"
                                onClick={handleClick}
                            >
                                Submit
                                <div className={classes.absoluteProgress}>
                                    <Fade
                                        in={loading}
                                        style={{
                                            transitionDelay: loading ? "800ms" : "0ms"
                                        }}
                                        unmountOnExit
                                    >
                                        <CircularProgress color="secondary" />
                                    </Fade>
                                </div>
                            </Button>
                        </Box>
                    </Grid>
                        </>
                    )}
                    {success && (
                        <Grid item lg={12}>
                            <Typography variant={"h5"} color={"secondary"} style={{fontWeight:"600"}}>You have unsubscribed</Typography>
                        </Grid>
                    )}
                </Grid>
            </Container>
        </Box>
    )
}

export default  UnsubscribeForm;