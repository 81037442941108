import React from "react";
import MainNavBar from "components/UIElements/MainNavBar";
import ThemeConfig from "Theme/ThemeConfig";
import FooterWidget from "components/UIElements/FooterWidget";
import Footer from "components/UIElements/Footer";
import SectionWithTitles from "./SectionWithTitles";
import SignUpForm from "./TherapistsSignUpForm";

import { ThemeProvider } from "@material-ui/core/styles";

const TherapistSignup = props => {
  return (

      <ThemeProvider theme={ThemeConfig}>
        <MainNavBar backgroundColor="transparent" logoBrightness="warm" />
        <SectionWithTitles />
        <SignUpForm backgroundColor="#ffffff" />
        <FooterWidget backgroundColor="#ffffff" />
        <Footer backgroundColor="#ffffff" />
      </ThemeProvider>

  );
};

export default TherapistSignup;
