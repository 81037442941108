import React, {useEffect, useState, useMemo, useCallback} from 'react'
import styled, {keyframes} from 'styled-components';
import DataTable from 'react-data-table-component';
import {CheckCircleOutlined,WarningRounded} from '@material-ui/icons'
import {API_URL} from 'config'
import {Box, Button, Typography} from '@material-ui/core'
import MatchedModal from 'pages/Admin/Matched/MatchedModal'

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  margin: 16px;
  animation: ___CSS_0___ 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;
const CustomLoader = () => (
  <div style={{padding: '24px'}}>
    <Spinner/>
    <div>Loading...</div>
  </div>
);

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;
const MatchedExpandableComponent = ({data}) => {
  return <>
    <Box>
      {data.therapists.map((therapist, index) => {
        return <div key={index}>
          <Box ml={4}>
            <Typography variant={'caption'}>{therapist.firstName} {therapist.lastName}</Typography>
          </Box>
        </div>
      })}
    </Box>
  </>
}
const FilterComponent = ({filterText, onFilter, onClear}) => (
  <>
    <TextField id="search" type="text" placeholder="Search" value={filterText} onChange={onFilter}/>
    <Button variant={'outlined'} color={'secondary'} onClick={onClear}>X</Button>
  </>
);
const MatchedList = props => {

  const [matchedRequests, setMatchedRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState({});
  const [therapists,setTherapists] = useState([]);
  const [open, setOpen] = useState(false)
  const [pending, setPending] = React.useState(true);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);


  const filteredItems = matchedRequests.filter(item => {
    return (
      item.id.toString().includes(filterText)
    )
  });

  async function getData(url = '') {

    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      }

    });
    return response.json(); // parses JSON response into native JavaScript objects
  }

  const getTherapists = () => {
    getData(`https://${API_URL}/api/admin/therapists`)
      .then(data => {
        setTherapists(data.content);
      });
  }


  const handleClose = (event) => {
    setOpen(!event);
  }

  const getMatchRequests = () => {
    getData(`https://${API_URL}/api/admin/matchrequests`)
      .then(data => {
        const timeout = setTimeout(() => {
          setMatchedRequests(data.content);
          setPending(false);
        }, 2000);
        return () => clearTimeout(timeout);

      });
  }

  useEffect(() => {
    getMatchRequests();
    getTherapists();
  }, [])

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return <>
      <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear}
                       filterText={filterText}/>
    </>
  }, [filterText, resetPaginationToggle]);

  const columns =
    [
      {
        name: 'Request Id',
        selector: 'id',
        sortable: true
      },
      {
        name: 'Patient Id',
        selector: 'patientId'
      },
      {
        name: 'Fulfilled',
        selector: 'hasBeenFulfilled',
        sortable: true,
        cell: row => <div>{row.hasBeenFulfilled ? <div><CheckCircleOutlined color={'primary'}/></div> : <div><WarningRounded color={'secondary'}/></div>}</div>
      },
      {
        cell: row =>
          <div>
            {row.hasBeenFulfilled ? <Button variant={'outlined'} color={'secondary'} disabled>Suggest</Button>
              :
              <Button variant={'outlined'} color={'secondary'} onClick={() => handleClickedPatient(row)}>Suggest</Button>
            }
          </div>
      }
    ];
  const handleClickedPatient = (event) => {
    setSelectedRequest(event);
    setOpen(true);
  }
  return <>
    <Box mb={4}>
      <DataTable
        pagination={true}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        paginationPerPage={10}
        title="Patient List"
        columns={columns}
        data={filteredItems}
        noHeader={true}
        highlightOnHover={true}
        expandableRows
        expandableRowsComponent={<MatchedExpandableComponent/>}
        expandOnRowClicked
        pagination
        paginationResetDefaultPage={resetPaginationToggle}
        progressPending={pending}
        progressComponent={<CustomLoader/>}
      />
      {open && <MatchedModal matchedDetails={selectedRequest} therapists = {therapists} closedModal={handleClose}/>}
    </Box>
  </>
}

export default MatchedList;