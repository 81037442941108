import React from "react";
import ThemeConfig from "Theme/ThemeConfig";
import { Redirect } from 'react-router'
import {makeStyles, ThemeProvider} from '@material-ui/core/styles';
import backgroundPattern from "assets/misc/pattern.png";
import ShareForm from "./ShareForm";

const ShareWithAfriend = props => {


  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const uuid = urlParams.get("uuid");
  const username = urlParams.get("username");

  return (
    <>
    {(uuid && username) === '' || (uuid && username) === null ? <Redirect to ='notfound'/> :null}
    <ThemeProvider theme={ThemeConfig}>
      <div style={{backgroundImage:`url(${backgroundPattern})`,backgroundSize:"auto, cover", minHeight:"500px" , height:"auto"}}>
        <ShareForm shareToken = {uuid} username = {username}/>
      </div>
    </ThemeProvider>
    </>
  );
};
export default ShareWithAfriend;