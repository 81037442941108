import React from "react";
import MainNavBar from "components/UIElements/MainNavBar";
import ThemeConfig from "Theme/ThemeConfig";
import FooterWidget from "components/UIElements/FooterWidget";
import Footer from "components/UIElements/Footer";
import TermsContent from "./TermsContent"
import { ThemeProvider } from "@material-ui/core/styles";

const Terms = props => {
    return (
        <ThemeProvider theme={ThemeConfig}>
            <MainNavBar backgroundColor="transparent" />
            <TermsContent/>
            <FooterWidget backgroundColor="#ffffff" />
            <Footer backgroundColor="#ffffff" />
        </ThemeProvider>
    );
};

export default Terms;
