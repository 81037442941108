import React from "react";
import MainNavBar from "components/UIElements/MainNavBar";
import ThemeConfig from "Theme/ThemeConfig";
import FooterWidget from "components/UIElements/FooterWidget";
import Footer from "components/UIElements/Footer";
import SectionWithImage from "./SectionWithImage";
import { ThemeProvider } from "@material-ui/core/styles";

const ContactUs = props => {
  return (
    <ThemeProvider theme={ThemeConfig}>
      <MainNavBar backgroundColor="transparent" />
      <SectionWithImage />
      <FooterWidget backgroundColor="#ffffff" />
      <Footer backgroundColor="#ffffff" />
    </ThemeProvider>
  );
};

export default ContactUs;
