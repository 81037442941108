import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {API_URL} from 'config';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Typography, Button, Box, Container, Fade} from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// const HolistikallyInput = withStyles(theme => ({
//   input: {
//     borderRadius: 30,
//     position: 'relative',
//     backgroundColor: '#eeece7',
//     border: '1px solid #ced4da',
//     fontSize: 16,
//     marginBottom: '20px',
//     boxShadow: 'inset 0 -2px 4px 0 #ffffff, inset 0 2px 4px 0 #4a4a4a66',
//     padding: '10px 12px',
//     transition: theme.transitions.create(['border-color', 'box-shadow']),
//     '&[type=\'password\']': {
//       boxShadow: 'inset 0 -2px 4px 0 #ffffff, inset 0 2px 4px 0 #4a4a4a66',
//       '&:focus': {
//         borderColor: theme.palette.primary.main,
//         opacity: '0.5'
//       },
//       '&::placeholder': {
//         color: theme.palette.secondary.main,
//         fontWeight: 'normal',
//         opacity: '1'
//       }
//     },
//     '&[type=\'email\']': {
//       boxShadow: 'inset 0 -2px 4px 0 #ffffff, inset 0 2px 4px 0 #4a4a4a66',
//       '&:focus': {
//         borderColor: theme.palette.primary.main,
//         opacity: '0.5'
//       },
//       '&::placeholder': {
//         color: theme.palette.secondary.main,
//         fontWeight: 'normal',
//         opacity: '1'
//       }
//     },
//     '&[type=\'text\']': {
//       boxShadow: 'inset 0 -2px 4px 0 #ffffff, inset 0 2px 4px 0 #4a4a4a66',
//       '&:focus': {
//         borderColor: theme.palette.primary.main,
//         opacity: '0.5'
//       },
//       '&::placeholder': {
//         color: theme.palette.secondary.main,
//         fontWeight: 'normal',
//         opacity: '1'
//       }
//     }
//   }
// }))(InputBase);
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  absoluteProgress: {
    position: 'absolute'
  },
  successSnackbar: {
    backgroundColor: '#97a49a'
  },
  warningSnackbar: {
    backgroundColor: '#df8166'
  },
  multiline: {
    borderRadius: 30,
    position: 'relative',
    backgroundColor: '#eeece7',
    border: '1px solid #ced4da',
    fontSize: 16,
    marginBottom: '20px',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    boxShadow: 'inset 0 -2px 4px 0 #ffffff, inset 0 2px 4px 0 #4a4a4a66'
  },
  inputBox: {
    borderRadius: 320,
    position: 'relative',
    backgroundColor: '#eeece7',
    border: '1px solid #ced4da',
    fontSize: 16,
    marginBottom: '20px',
    boxShadow: 'inset 0 -2px 4px 0 #ffffff, inset 0 2px 4px 0 #4a4a4a66',
    paddinLeft: '40px',
    transition: theme.transitions.create(['border-color', 'box-shadow'])
  }
}));
const ContactUsForm = props => {
  const {backgroundColor, backgroundImage} = props;
  const classes = useStyles();

  const [fullName, setFullName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [mailSubject, setMailSubject] = useState('');
  const [mailBody, setMailBody] = useState('');

  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState('success');
  const [loading, setLoading] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(false);
  const [messageColor, setMessageColor] = useState(classes.successSnackbar);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const handleFullNameChange = e => {
    setFullName(e.target.value);
  };

  const handleEmailChange = e => {
    setEmailAddress(e.target.value);
  };

  const handleTitleChange = e => {
    setMailSubject(e.target.value);
  };

  const handleBodyChange = e => {
    setMailBody(e.target.value);
  };

  const handleCaptcha = () => {
    setCaptchaValue(true)
  }

  const sendEmail = e => {
    const payload = {
      body: mailBody,
      subject: mailSubject,
      email: emailAddress,
      name: fullName
    };
    setLoading(prevLoading => !prevLoading);

    fetch(`https://${API_URL}/contactus`, {
      method: 'post',
      body: JSON.stringify(payload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.status) {
          setSnackSeverity('warning');
          setSnackMessage(data.message);
          setMessageColor(classes.warningSnackbar);
        } else {
          setSnackSeverity('success');
          setMessageColor(classes.successSnackbar);
          setSnackMessage('Thank you for your email!');
        }
        setFullName('');
        setEmailAddress('');
        setMailBody('');
        setMailSubject('');
        setOpen(true);
        setLoading(prevLoading => !prevLoading);
      })
      .catch(function () {
        setOpen(true);
        setSnackSeverity('warning');
        setSnackMessage('Your email wasn\'t send due to an error from our side');
        setMessageColor(classes.warningSnackbar);
      });

    e.preventDefault();
  };

  return (
    <>
      <Box
        style={{
          background: backgroundColor
            ? backgroundColor
            : `url(${backgroundImage}) no-repeat center center fixed`,
          backgroundSize: 'cover'
        }}
        pb={10}
        pt={10}
      >
        <Container>
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            justify="flex-start"
          >
            <Grid item lg={10} md={10} sm={12} xs={12}>
              <Box display="flex" mb={1} justifyContent="left">
                <Typography variant="h4" style={{color: '#4a4a4a'}}>
                  Contact Us!
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justify="flex-start"
            alignContent="center"
          >
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <Box mt={4}>
                <form onSubmit={sendEmail}>
                  <Box className={classes.inputBox}>
                    <TextField
                      label="Full Name"
                      InputProps={{disableUnderline: true}}
                      style={{marginLeft: '18px', minWidth: '320px'}}
                      required
                      onChange={handleFullNameChange}
                      value={fullName}
                      fullWidth
                      autoComplete={'off'}
                    />
                  </Box>
                  <Box className={classes.inputBox}>
                    <TextField
                      fullWidth
                      label="Email Address"
                      InputProps={{disableUnderline: true}}
                      style={{marginLeft: '18px', minWidth: '320px'}}
                      required
                      onChange={handleEmailChange}
                      value={emailAddress}
                    />
                  </Box>

                  <Box className={classes.inputBox}>
                    <TextField
                      fullWidth
                      label="Title of Message"
                      InputProps={{disableUnderline: true}}
                      style={{marginLeft: '18px', minWidth: '320px'}}
                      required
                      onChange={handleTitleChange}
                      value={mailSubject}
                    />
                  </Box>

                  <InputBase
                    placeholder="Details of your message"
                    className={classes.multiline}
                    multiline={true}
                    rows={8}
                    rowsMax={8}
                    fullWidth
                    value={mailBody}
                    required
                    onChange={handleBodyChange}
                  />

                  <Box style={{margin:'0 auto'}}>
                    <ReCAPTCHA sitekey={'6LfucekUAAAAAGUyPJP5WJrG73YuAoXr_YzTCGQ3'} onChange={handleCaptcha}/>
                  </Box>

                  <Box mt={1}>
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      size="large"
                      type="submit"
                      style={{height: '60px'}}
                      disabled={loading || !captchaValue}
                    >
                      Send
                      <div className={classes.absoluteProgress}>
                        <Fade
                          in={loading}
                          style={{
                            transitionDelay: loading ? '800ms' : '0ms'
                          }}
                          unmountOnExit
                        >
                          <CircularProgress color="secondary"/>
                        </Fade>
                      </div>
                    </Button>
                  </Box>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackSeverity}
          className={messageColor}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ContactUsForm;
