import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Container, Box } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import { ReactComponent as Logo } from "assets/logos/logo.svg";
import { ReactComponent as LogoWarm } from "assets/logos/logo-warm.png";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
    color: "#97a49a"
  },
  buttonAction: {
    boxShadow: "0 2px 4px 0 rgba(74, 74, 74, 0.4)",
    backgroundColor: "#97a49a",
    color: "#ffffff"
  },
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  }
}));

export default function MainNavBar(props) {
  const { backgroundColor, logoBrightness } = props;
  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  const history = useHistory();

  const handleLinkClick = page => {
    history.push(`/${page}`);
  };

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

   setOpen(false)
  };

  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom"
      })}
      style={{color:"#ffffff"}}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button key={1} onClick={()=>handleLinkClick("about")}>
          <ListItemText primary={"About Us"} />
        </ListItem>
      </List>
      <Divider color={"#fffff"} />
      <List>
        <ListItem button key={2} onClick={()=>handleLinkClick("faq")}>
          <ListItemText primary={"FAQ"} />
        </ListItem>
      </List>
      <Divider color={"#fffff"}/>
      <List>
        <ListItem button key={3} onClick={()=>handleLinkClick("contactus")}>
          <ListItemText primary={"Contact Us"} />
        </ListItem>
      </List>
      <Divider color={"#fffff"}/>
      <List>
        <ListItem button key={4} onClick={()=>handleLinkClick("user/signup")}>
          <ListItemText primary={"Match with a coach"} />
        </ListItem>
      </List>
      <Divider color={"#fffff"}/>
      <List>
        <ListItem button key={5} onClick={()=>handleLinkClick("coach/signup")}>
          <ListItemText primary={"Join as a Coach"} />
        </ListItem>
      </List>
      <Divider color={"#fffff"}/>
    </div>
  );

  return (
    <Box style={{ backgroundColor: backgroundColor }} pt={5}>
      <Box className={"body_wrapper"}>
      <Container>
        <AppBar color={"transparent"} position="static" >
          <Toolbar disableGutters={true}>
            <Typography variant="h6" className={classes.title}>
              <a href="/">
                {logoBrightness === "warm" ? <LogoWarm /> : <Logo />}
              </a>
            </Typography>

            <Hidden xsDown smDown>
              <Button
                color="primary"
                href="/about"
                style={{ fontSize: "16px" }}
              >
                About Us
              </Button>
              <Button color="primary" href="/faq" style={{ fontSize: "16px" }}>
                FAQ
              </Button>
              <Button
                color="primary"
                href="/contactus"
                style={{ fontSize: "16px" }}
              >
                Contact Us
              </Button>
              <Button
                color="primary"
                variant="contained"
                href="/user/signup"
                style={{ color: "#ffffff", minWidth: "200px" }}
              >
                Match with a Coach
              </Button>
              <Button
                color="secondary"
                variant="contained"
                href="/coach/signup"
                style={{ color: "#ffffff", minWidth: "200px" }}
              >
                Join as a Coach
              </Button>
            </Hidden>

            {/* Mobile Menu*/}
            <Hidden only={["xl", "lg", "md"]}>
              <Button
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
                style={{ marginTop: "-4px" }}
              >
                <MenuIcon />
              </Button>
              <Drawer open={open} anchor={"right"} onClose={toggleDrawer("right", false)} >
                {list("right")}
              </Drawer>
            </Hidden>
          </Toolbar>
        </AppBar>
      </Container>
      </Box>
    </Box>
  );
}
