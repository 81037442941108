import React from "react";

// Banner Section *
import BannerWithTitles from "components/VisualComponents/BannerWithTitles";

import Illustrations from "assets/IllustrationsList";
import Backgrounds from "assets/BackgroundList";

const SectionWithTitles = props => {
  return (
    <BannerWithTitles
      backgroundColor="transparent"
      sectionTitle="Why life coaches love us"
      sectionTitleVariant="h4"
      sectionTitleColor="primary"
      sectionFontWeight="400"
      sectionImage={Illustrations.reading}
    />
  );
};

export default SectionWithTitles;
