import React from 'react';
import MainNavBar from 'components/UIElements/MainNavBar';
import ThemeConfig from 'Theme/ThemeConfig';
import FooterWidget from 'components/UIElements/FooterWidget';
import Footer from 'components/UIElements/Footer';
import Illustrations from 'assets/IllustrationsList';
import {ThemeProvider} from '@material-ui/core/styles';
import {Grid, Typography, Container,Box} from '@material-ui/core'
import Button from '@material-ui/core/Button'

const NotFound = () => {
  return (
    <ThemeProvider theme={ThemeConfig}>
      <MainNavBar backgroundColor="transparent"/>
      <Container>
        <Grid
          container
          direction="row"
          alignContent="flex-start"
          justify="center"
          alignItems="flex-start"
        >
          <Grid item lg={12}>
            <Box mt={6} mb={2}>
              <Typography variant={'h3'} style={{fontWeight: 'bold'}} color={'secondary'} align={'center'}>
                Sorry!
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={3} md={12} xs={12} sm={12}>
            <Box mb={2}>
              <Typography style={{fontSize: '24px'}} align={'center'}>
                We can’t find the page you are looking for!

              </Typography>

            </Box>
          </Grid>
          <Grid item lg={12}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Grid item lg={12}>
                <Box mb={2}>
                  <img src={Illustrations.plantCare} alt={'not found page'} width={'400px'} height={'100%'}/>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction='row' alignItems='center' justify='space-around' alignContent='center'>
          <Grid item lg={3} md={3} xs={12} sm={6}>
            <Box mb={8}>
              <Button variant={'contained'} fullWidth color={'primary'} style={{padding:'15px 0 15px 0'}} href={'/'}>
                Back to home
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <FooterWidget backgroundColor="#ffffff"/>
      <Footer backgroundColor="#ffffff"/>
    </ThemeProvider>
  );
};

export default NotFound;
