import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Facebook, Instagram } from "@material-ui/icons";
import { Container, Box, Grid } from "@material-ui/core";
import CookieNotice from "components/UIElements/CookieNotice";

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 2
  },
  buttonAction: {
    boxShadow: "0 2px 4px 0 rgba(74, 74, 74, 0.4)",
    backgroundColor: "#97a49a",
    color: "#ffffff"
  },
  boxLayout: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  }
}));

export default function Footer(props) {
  const { backgroundColor } = props;

  const classes = useStyles();

  return (
    <Box
      style={{
        backgroundColor: backgroundColor,
        borderTop: "solid 1px",
        borderColor: "#2b292d36",
        bottom: 0
      }}
      className={classes.boxLayout}
    >
      <Box className={"body_wrapper"}>
      <Container fixed>
        <Grid container justify="space-between">
          <Grid item lg={8}>
            <Box mt={2} mb={2}>
              <Typography
                display="inline"
                variant="body1"
                style={{
                  marginRight: "15px",
                  lineHeight: 1.57,
                  fontSize: "14px",
                  color: "#2b292d99"
                }}
              >
                <a href ="/terms" style={{textDecoration:"none",color:"rgba(43, 41, 45, 0.6)"}}>Terms of Use</a>
              </Typography>

              <Typography
                display="inline"
                variant="body1"
                style={{
                  marginRight: "15px",
                  lineHeight: 1.57,
                  fontSize: "14px",
                  color: "#2b292d99"
                }}
              >
                <a href ="/privacypolicy" style={{textDecoration:"none",color:"rgba(43, 41, 45, 0.6)"}}>Privacy Policy</a>
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={2}>
            <Box mt={2} mb={2}>
              <Box mr={2} display="inline">
                <a
                  href="https://www.facebook.com/"
                  target="_blank"
                >
                  <Facebook color="primary" />
                </a>
              </Box>

              <Box mr={2} display="inline">
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                >
                  <Instagram color="primary" />
                </a>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      </Box>
      <CookieNotice />
    </Box>

  );
}
