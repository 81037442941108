import React from "react";

import Grid from "@material-ui/core/Grid";

import { Typography, Box, Container } from "@material-ui/core";

const CardsSection = props => {
  const {
    sectionTitle,
    sectionTitleVariant,
    sectionSubtitle,
    sectionSubtitleVariant,
    sectionCardsContent,
    backgroundColor,
    backgroundImage
  } = props;

  return (
    <>
      <Box
        style={{
          background: backgroundColor
            ? backgroundColor
            : `url(${backgroundImage}) no-repeat center center fixed`,
          backgroundSize: "cover"
        }}
        pb={10}
        pt={10}
      >
        <Box className={"body_wrapper"}>
        <Container >
          <Grid
            container
            direction="row"
            justify="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid item lg={6} md={6} xs={12} sm={12}>
              <Typography
                color="secondary"
                variant={sectionTitleVariant}
                align="center"
                style={{
                  fontWeight: "bolder",
                  fontSize: "28px"
                }}
              >
                {sectionTitle}
              </Typography>

              <Typography
                variant={sectionSubtitleVariant}
                align="center"
                style={{ fontSize: "24px" }}
              >
                {sectionSubtitle}
              </Typography>
            </Grid>
          </Grid>

          {/* Cards */}
          <Box mt={8}>
            <Grid
              container
              direction="row"
              alignContent="center"
              justify="center"
              alignItems="flex-start"
            >
              {sectionCardsContent.map((card, index) => {
                return (
                  <Grid key={index} item lg={4} md={4} xs={12} sm={12} p={2}>
                    <Grid
                      container
                      direction="row"
                      alignContent="center"
                      justify="center"
                    >
                      <Grid item lg={8} md={8} sm={12} xs={12}>
                        <img
                          src={card.image}
                          width="100%"
                          alt="Section thumbnail"
                        />
                      </Grid>
                      <Grid item lg={12} sm={12} md={12} xs={12}>
                        <Box mt={3}>
                          <Typography
                            gutterBottom
                            variant="h5"
                            align="center"
                            style={{
                              fontWeight: "bolder",
                              marginBottom: "10px",
                              minHeight: "60px"
                            }}
                          >
                            {card.title}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box mb={5} mt={2} pr={6} pl={6}>
                          <Typography
                            gutterBottom
                            align="center"
                            style={{
                              lineHeight: "1.67",
                              letterSpacing: "-0.5px",
                              fontWeight: "normal",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              fontSize: "18px"
                            }}
                          >
                            {card.content}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Container>
        </Box>
      </Box>
    </>
  );
};

export default CardsSection;
