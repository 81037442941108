import React, {useEffect} from 'react';


const CheckToken = props =>{
  const {token} = props;

  function decodeToken(token) {
    if (!token) return null
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }
  function getUser() {
    return decodeToken(localStorage.getItem('access_token'))
  }
  const expiration = getUser() ? getUser().exp : null;
  useEffect(()=>{
    if (expiration !== null) {
      if (Date.now() >= expiration * 1000) {
        localStorage.removeItem('access_token');
        window.location.reload();
      }
    }
  },[token,expiration])
  return <></>
}

export default CheckToken;