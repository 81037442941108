import React from "react";

// Banner Section *
import BannerSection from "../../components/VisualComponents/BannerSection";

import Backgrounds from "assets/BackgroundList";
import Illustrations from "assets/IllustrationsList";

const HomeBanner = props => {
  return (
    <BannerSection
      sectionTitle="Your identity matters to your health"
      sectionTitleVariant="h3"
      sectionTitleFontSize="48px"
      sectionContent="We are a team of life coaches, technologists and investors who are on a mission to democratize access to life coaching care using technology."
      sectionFontWeight="700"
      buttonVariant="contained"
      buttonColor="primary"
      buttonLabel="Sign Up"
      buttonHref="user/signup"
      sectionImage={Illustrations.outOfTheBox}
      sectionImageAlign="right"
      backgroundImage={Backgrounds.light}
      showTerms={false}
      includeNavBar={true}
    />
  );
};

export default HomeBanner;
