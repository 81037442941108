import React from "react";

// Banner Section *
import BannerImage from "components/VisualComponents/BannerImage";

import Illustrations from "assets/IllustrationsList";
// import Backgrounds from "assets/BackgroundList";

const SectionWithImage = props => {
  return (
    <BannerImage sectionImage={Illustrations.sitting} showForm="contactForm" />
  );
};

export default SectionWithImage;
